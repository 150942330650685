import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link, useNavigate } from "react-router-dom";
import { calculateDiscount, formatOption, getCurrentUser, handleImageError, setCurrentUserProfilePercent, setCurrentUserProfilePercentWithResponse, trimAndAddEllipsis } from "helpers/functions";



import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import Bootstrap Select CSS
import $ from 'jquery'; // Import jQuery
// import 'bootstrap-select'; // Import Bootstrap Select JS




import Header from "./Header";
import Footer from "./Footer";
import MyAnswer from "./MyAnswer";

const MyPanel = (props) => {
  document.title = "My Panel - Virtual Skills Platform";

  const animatedComponents = makeAnimated();
 

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [recommendationLoading, setRecommendationLoading] = useState(true)
  const [isEditMode, setEditMode] = useState(false)
  const [isQualificationEditMode, setQualificationEditMode] = useState(false)
  const [jobRoleList, setJobRoleList] = useState([])
  const [qualificationList, setQualificationList] = useState([])


  const [skillsBasedCourses, setSkillsBasedCourses] = useState([])
  const [desiredRoleBasedCourses, setDesiredRoleBasedCourses] = useState([])
  const [openedCoursesBased, setOpenedCoursesBased] = useState([])
  const [currentDesiredRoleCourse, setCurrentDesiredRoleCourse] = useState(null)
  const [openedCourseDetail, setOpenedCourseDetail] = useState(null)
  const [savedCoursesBased, setSavedCoursesBased] = useState([])
  const [savedCourseDetail, setSavedCourseDetail] = useState(null)

  const navigate = useNavigate()

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  
  const [formValues, setFormValues] = useState({
    desired_role: '-',
  })

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: formValues,
    validationSchema: Yup.object({
        // desired_role: Yup.string().required("Please enter desired role"),
        desired_role: Yup.object().nullable().required("Please select desired role"),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here
      
      setLoading(true);

      let newBody = Object.assign({}, values);

      newBody.desired_job_role_id = newBody.desired_role.value;
      delete newBody.desired_role
      
      console.log("SUBMITTED",newBody)
    //   setTimeout(() => {
    //     setLoading(false)
    //     setEditMode(false)
    //   },3000)
      post(url.SAVE_DESIRED_ROLE, newBody, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

         
        let message = response?.response || "";
        let responseData = response?.data || [];

        setCurrentUserProfilePercentWithResponse(response);

        let existingUserObj = getCurrentUser();

        console.log("U",existingUserObj,response)

        const updatedObject = { ...existingUserObj, ...responseData };

        localStorage.setItem("authUser", JSON.stringify(updatedObject));
        
        window.alert("Success!\n"+message)
        // window.history.go(-1);
        setLoading(false)
        setEditMode(false)

      }).catch((e) => {
        window.alert("Sorry!\n"+e)
        setLoading(false)
        setEditMode(false)
      }).finally(() => {
      });   
      
     
    },
  });
  
  const [qualificationFormValues, setQualificationFormValues] = useState({
    qualification_id: '-',
  })

  const qualification_validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: qualificationFormValues,
    validationSchema: Yup.object({
        qualification_id: Yup.object().nullable().required("Please select qualification"),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here
      
      setLoading(true);

      let newBody = Object.assign({}, values);

      newBody.qualification_id = newBody.qualification_id.value;
      
      console.log("SUBMITTED",newBody)
    //   setTimeout(() => {
    //     setLoading(false)
    //     setEditMode(false)
    //   },3000)
      post(url.SAVE_HIGHEST_QUALIFICATION, newBody, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

         
        let message = response?.response || "";
        let responseData = response?.data || [];

        setCurrentUserProfilePercentWithResponse(response);

        let existingUserObj = getCurrentUser();

        console.log("U",existingUserObj,response)

        const updatedObject = { ...existingUserObj, ...responseData };

        localStorage.setItem("authUser", JSON.stringify(updatedObject));
        
        window.alert("Success!\n"+message)
        // window.history.go(-1);
        setLoading(false)
        setQualificationEditMode(false)

      }).catch((e) => {
        window.alert("Sorry!\n"+e)
        setLoading(false)
        setQualificationEditMode(false)
      }).finally(() => {
      });   
      
     
    },
  });

    const handleDropdownSelect = (context,selectedOptions,key) => {
        context.setFieldValue(key, selectedOptions);
    };
  
  useEffect(() => {
    
  })


  useEffect(() => {
    async function fetchData() {

        get(url.GET_MY_PANEL, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

            // setLoading(false)

            // console.log("RESPONSE ",response)

            let responseData = response.data

            setCurrentUserProfilePercentWithResponse(response);

            let jbs = []

            responseData.job_roles.map((j,i) => {
                let jobDesc = j.description ? "("+j.description+")" : ""
                jbs.push({ label : `${j.name} ${jobDesc}`, value : j.job_role_id })
            })

            let qls = []

            responseData.qualifications.map((j,i) => {
                qls.push({ label : j.name, value : j.qualification_id })
                console.log("Q ",qls)
            })

            setJobRoleList(jbs)
            setQualificationList(qls)

            // console.log("D ",responseData)
            // desired_role: responseData?.userWithDesiredJobRole?.desiredJobRole?.name || "-",
            setFormValues({
                desired_role: responseData.userWithDesiredJobRole.desiredJobRole ? { label: responseData?.userWithDesiredJobRole?.desiredJobRole?.name, value: responseData?.userWithDesiredJobRole?.desiredJobRole?.job_role_id } : null,
            })
            setQualificationFormValues({
                qualification_id: responseData.userWithDesiredJobRole.userQualification ? { label: responseData?.userWithDesiredJobRole?.userQualification?.name, value: responseData?.userWithDesiredJobRole?.userQualification?.qualification_id } : null,
            })
            setLoading(false)

        }).catch((e) => {
            window.alert("Sorry!\n"+e)
        }).finally(() => {
            // setLoading(false)
        });   
        
        // setLoading(false)
      
    
    }
    
    fetchData();




    async function getRecomendations() {
        setRecommendationLoading(true)

        let userInfo = getCurrentUser();

        let body = {}
        if(userInfo != null) {
            // console.log("USER INFO ",userInfo)
            body.user_id = userInfo.user_id
        } else {
            // console.log("USER INFO BLANK" )
        }

        // get(GET_SEARCH_RESULT+`?c=${searchC}&q=${searchQ}`, {}, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {
        post(url.GET_RECOMMENDATIONS, {}, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {

          console.log("FETCHED ", response)

          setCurrentUserProfilePercentWithResponse(response);

          let responseData = response?.data || [];

            setSkillsBasedCourses(responseData?.skillsBasedCourses?.courses || [])
            setDesiredRoleBasedCourses(responseData?.desiredJobRolesBasedCourses?.courses || [])
            setCurrentDesiredRoleCourse(responseData?.basedOnOpenedCourses?.openedCourse || null)
            setOpenedCoursesBased(responseData?.basedOnOpenedCourses?.courses || [])
            setOpenedCourseDetail(responseData?.basedOnOpenedCourses?.openedCourse || null)
            setSavedCoursesBased(responseData?.basedOnSavedCourses?.courses || [])
            setSavedCourseDetail(responseData?.basedOnSavedCourses?.savedCourse || null)

        //   setSearchResults(responseData)

          
        setRecommendationLoading(false)

        }).catch((e) => {
          window.alert("Sorry!\n" + JSON.stringify(e))
          setRecommendationLoading(false)
        }).finally(() => {
        //   setLoading(false)
        });

    }
    getRecomendations();
    
    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 

  const redirectToCourse = (item) => {
          

    let userInfo = getCurrentUser();
  
    let body = {
        course_link : item?.course_link || "#",
        courses_id : item.courses_id
    }
  
    if(userInfo != null) {
        // console.log("USER INFO ",userInfo)
        body.user_id = userInfo.user_id
    } else {
        // console.log("USER INFO BLANK" )
    }
  
    if(body.course_link == "#") {
      window.open(body.course_link, '_blank');
      return
    } else {
      window.open(body.course_link, '_blank');
      post(url.REDIRECT_TO_COURSE_URL, body, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {
  
      //   console.log("FETCHED ", response)
  
          let redirectUrl = response?.data || "#";
  
          // const { redirectUrl } = response.data;
  
          // Redirect to the new page in a new tab
          
  
          
          // setLoading(false)
  
      }).catch((e) => {
          window.alert("Sorry!\n" + e)
          // setLoading(false)
      }).finally(() => {
      //   setLoading(false)
      });
    }
  }

//   const renderCourseThumb = (course,index) => {
//         return(
//             <div className="" style={{ marginLeft : 0, marginRight : 0, width : '90%' }}>
//                 <div className="category_box" style={{ borderStyle : 'solid', borderWidth : 1, borderColor: "#ccc", }}>
//                     <div className="inn">
//                         {/* <img style={{ marginBottom : 20 }} src="../images/course_img_2.jpg" alt="" /> */}
//                         <img style={{ marginBottom : 20 }} onError={handleImageError} src={url.API_BASE_URL+course?.institute_detail?.image || "#"} alt="" />
                        
//                         <h3 className="h3-custom clamp-text-2">{course?.title}</h3>
//                         <span>{course?.institute_detail?.name}</span>
//                         <p style={{ margin : 0 }}>{course?.category_detail?.name}</p>
//                     </div>
//                 </div>
//         </div>)
//     }

const removeParentheses = (input) => {
    return input.replace(/\(.*?\)/g, '').trim();
  }

const renderCourseThumb = (course,index) => {
    return(
        <div style={{ marginLeft : 0, marginRight : 0, width : '90%' }}>
            <div className="category_box" style={{ borderStyle : 'solid', borderWidth : 1, borderColor: "#ccc", }}>
                <div className="inn">
                    {/* <img style={{ marginBottom : 20 }} src="../images/course_img_2.jpg" alt="" /> */}
                    <div className="custom-image-container">
                        <img style={{ marginBottom : 0 }} onError={handleImageError} src={url.API_BASE_URL+course?.institute_detail?.image || "#"} alt="" /></div>
                    
                    <h3 className="h3-custom clamp-text-2">{course?.title}</h3>
                    <span className="clamp-text-1">{course?.institute_detail?.name}</span>
                    <p className="clamp-text-1" style={{ margin : 0 }}>{course?.category_detail?.name}</p>
                </div>
                <button type="button" onClick={() => redirectToCourse(course)}  class="btns gray mt-20">View Course</button>
            </div>
    </div>)
}


  return (
    <React.Fragment>
    { 
    // isLoading ? <><Header /><section className="about_sec"><div className="container text-center"><Spinners  /></div></section></> :
      <>
                    
                
                <Header />


            <section className="about_sec">
                <Form className="needs-validation"  onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}> 
                    <div className="container">
                      
                        
                    {/* <!-- Panel --> */}
                    <div className="about_main">
                        <ul className="breadcrumb">
                            <li><Link to="/dashboard" href="#" title="Dashboard">Dashboard</Link></li>
                            <li><a href="#" title="About Me">About Me</a></li>
                            <li>My Panel</li>
                        </ul>
                        { isLoading ? 
                            <><div className="container text-center mt-20"><Spinners  /></div></> 
                        :
                            // <div className="panel_sec" style={{ minHeight : 250 }}>
                            <div className="panel_sec mt-4 mb-5">

                                <div className="row align-items-end justify-content-between mb-4 mb-xl-5 g-0">
                                    <div className="col-md-auto col-sm-12">
                                        <h3>My Desired Job Role</h3>
                                    </div>
                                    <div className="col-md-auto col-sm-12 text-end">
                                        
                                    </div>
                                </div>

                                <div className="panel_block" style={{ overflow : "visible" }}>
                                    <div className="panel_item">
                                        <div className="icon"><img src="/images/panel_ic.svg" alt="" /></div>
                                        <div className="rdtl">
                                            <h5 style={{ width: '30%' }}>My Current Desired Role is</h5>
                                            {/* <div className="panel_dtl">{formValues.desired_role}</div> */}
                                            <div className="panel_dtl" style={{ width : '70%' }}>
                                            {
                                                isEditMode ?
                                                    <>
                                                        <Select
                                                            value={validation.values.desired_role}
                                                            isMulti={false}
                                                            onChange={(selectedOptions) => handleDropdownSelect(validation,selectedOptions,"desired_role")}
                                                            options={jobRoleList}
                                                            id="desired_role"
                                                            classNamePrefix="selectpicker"
                                                            isValid={!validation.errors.desired_role && validation.touched.desired_role}
                                                        />
                                                        {validation.touched.desired_role && validation.errors.desired_role ? (
                                                            <div className="text-danger">{validation.errors.desired_role}</div>
                                                        ) : null}
                                                    </>
                                                    :
                                                    <>{removeParentheses(validation.values?.desired_role?.label || "-")}</>
                                            }
                                            </div>
                                            {/* <p>IT </p> */}
                                        </div>
                                    </div>
                                    {/* <a href="#" className="more_link"><i className="fa fa-angle-right"></i></a> */}
                                    {isEditMode ? 
                                        <a onClick={(e) => {
                                            e.preventDefault()
                                            validation.submitForm()
                                        }} className="more_link">{`Save`}</a>
                                        : 
                                        <a onClick={(e) => {
                                            e.preventDefault()
                                            setEditMode(true)}
                                        } className="more_link">{ validation.values.desired_role == "-" ? `Set` : `Change`}</a>
                                    }

                                    
                                </div>


                                {/* QUALIFICATION SET */}
                                <div className="row align-items-end justify-content-between mb-4 mb-xl-5 g-0">
                                    <div className="col-md-auto col-sm-12">
                                        <h3>Enter Your Qualification Details</h3>
                                    </div>
                                    <div className="col-md-auto col-sm-12 text-end">
                                        
                                    </div>
                                </div>

                                <div className="panel_block" style={{ overflow : "visible" }}>
                                    <div className="panel_item">
                                        <div className="icon"><img src="/images/panel_ic.svg" alt="" /></div>
                                        <div className="rdtl">
                                            <h5 style={{ width: '30%' }}>My Highest Qualification</h5>
                                            {/* <div className="panel_dtl">{formValues.desired_role}</div> */}
                                            <div className="panel_dtl" style={{ width : '70%' }}>
                                            {
                                                isQualificationEditMode ?
                                                    <>
                                                        <Select
                                                            value={qualification_validation.values.qualification_id}
                                                            isMulti={false}
                                                            onChange={(selectedOptions) => handleDropdownSelect(qualification_validation,selectedOptions,"qualification_id")}
                                                            options={qualificationList}
                                                            id="qualification_id"
                                                            classNamePrefix="selectpicker"
                                                            isValid={!qualification_validation.errors.qualification_id && qualification_validation.touched.qualification_id}
                                                        />
                                                        {qualification_validation.touched.qualification_id && qualification_validation.errors.qualification_id ? (
                                                            <div className="text-danger">{qualification_validation.errors.qualification_id}</div>
                                                        ) : null}
                                                    </>
                                                    :
                                                    <>{qualification_validation.values?.qualification_id?.label || "-"}</>
                                            }
                                            </div>
                                            {/* <p>IT </p> */}
                                        </div>
                                    </div>
                                    {/* <a href="#" className="more_link"><i className="fa fa-angle-right"></i></a> */}
                                    {isQualificationEditMode ? 
                                        <a onClick={(e) => {
                                            e.preventDefault()
                                            qualification_validation.submitForm()
                                        }} className="more_link">{`Save`}</a>
                                        : 
                                        <a onClick={(e) => {
                                            e.preventDefault()
                                            setQualificationEditMode(true)}
                                        } className="more_link">{ qualification_validation.values.qualification_id == "-" ? `Set` : `Change`}</a>
                                    }

                                    
                                </div>
                                {/* QUALIFICATION SET END */}



                                <MyAnswer />
                                {/* <div className="panel_box">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="panel_info">
                                                <div className="inn">
                                                    <figure><img src="/images/panel_box_img1.svg" alt="" /></figure>
                                                    <h5>Attended Trainings</h5>
                                                    <p>Your attended trainings</p>
                                                    <span>0</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="panel_info">
                                                <div className="inn">
                                                    <figure><img src="/images/panel_box_img2.svg" alt="" /></figure>
                                                    <h5>Saved Searches</h5>
                                                    <p>Your saved searches</p>
                                                    <span>0</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                { recommendationLoading ? 
                                
                                <div className="container text-center"><Spinners  /></div>
                                
                                : 
                                
                                <section className="category_sec" id="category_section" style={{backgroundColor:"white", paddingTop : 0}}>
                                              

                                { ((openedCoursesBased.length > 0 && openedCourseDetail) || desiredRoleBasedCourses.length > 0 || skillsBasedCourses.length > 0) && <h2 className="global_title " style={{ marginBottom : 50,}}>What to learn next?</h2>}

                                { openedCoursesBased.length > 0 && openedCourseDetail &&
                                    <>
                                        <h4 className="custom-carousel-title">Because you view <a target="_blank" href={openedCourseDetail?.course_link || "#"} className="">"{openedCourseDetail?.title || ""}"</a></h4>
                                        <div className="row">
                                        {/* <OwlCarousel options={options} className="owl-carousel EverydayCarousel" items={4}  dots={false} margin={28}> */}
                                        <Carousel responsive={responsive}>
                                        { openedCoursesBased.map(renderCourseThumb)}
                                        </Carousel>
                                        </div>
                                    </>
                                }

                                <br />

                                { savedCoursesBased.length > 0 && savedCourseDetail &&
                                    <>
                                        <h4 className="custom-carousel-title">Because you liked <a target="_blank" href={savedCourseDetail?.course_link || "#"} className="">"{savedCourseDetail?.title || ""}"</a></h4>
                                        <div className="row">
                                        {/* <OwlCarousel options={options} className="owl-carousel EverydayCarousel" items={4}  dots={false} margin={28}> */}
                                        <Carousel responsive={responsive}>
                                        { savedCoursesBased.map(renderCourseThumb)}
                                        </Carousel>
                                        </div>
                                    </>
                                }

                                <br />


                                { desiredRoleBasedCourses.length > 0 &&
                                    <>
                                        <h4 className="custom-carousel-title">Recommended for you based on your desired role</h4>
                                        <div className="row">
                                        {/* <OwlCarousel options={options} className="owl-carousel EverydayCarousel" items={4}  dots={false} margin={28}> */}
                                        <Carousel responsive={responsive}>
                                        { desiredRoleBasedCourses.map(renderCourseThumb)}
                                        </Carousel>
                                        </div>
                                    </>
                                }

                                <br />

                                { skillsBasedCourses.length > 0 &&
                                    <>
                                        <h4 className="custom-carousel-title">Recommended for you based on your skills</h4>
                                        <div className="row">
                                        {/* <OwlCarousel options={options} className="owl-carousel EverydayCarousel" items={4}  dots={false} margin={28}> */}
                                        <Carousel responsive={responsive}>
                                        { skillsBasedCourses.map(renderCourseThumb)}
                                        </Carousel>
                                        </div>
                                    </>
                                }

                                { (!recommendationLoading && !((openedCoursesBased.length > 0 && openedCourseDetail) || desiredRoleBasedCourses.length > 0 || skillsBasedCourses.length > 0)) && <button onClick={() => navigate('/aboutme/my-profile')} className="btns blue build-profile-btn">Complete your profile first to see our recommendation for you <img src="/images/right_arrow_ic.png" style={{ height : 30 }} alt="" /></button>}
                                
                                    
                                </section>}
                            </div>
                        }
                    </div>

                    </div>
                </Form>
            </section>

            <Footer />
                
      </>
    }
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(MyPanel);