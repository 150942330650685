import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { calculateDiscount, formatOption, getCompletedFields, getCurrentUser, getCurrentUserProfilePercent, getRemainingFields, setCurrentUserProfilePercentWithResponse, trimAndAddEllipsis } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Modal, Dropdown, Form, FormControl } from 'react-bootstrap'; // Import Bootstrap modal functionality

import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";



const Intro = (props) => {
    
    const navigate = useNavigate();

    const [isTop, setIsTop] = useState(false);
    const [isRight, setIsRight] = useState(false);


    const toggleTopCanvas = () => {
        setIsTop(!isTop);
    };
    
    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };

    const popoverBtn = React.useRef(null)
    const animatedComponents = makeAnimated();
    
    const location = useLocation();
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)

    const [name, setName] = useState('');
    const [profileCompletion, setProfileCompletion] = useState(0);
    const [remainingFieldsList, setRemainingFieldsList] = useState([]);
    const [completedFieldsList, setCompletedFieldsList] = useState([]);

    const fetchProfileData = () => {
        const storedName = getCurrentUser()?.first_name || "User";
        const storedProfileCompletion = getCurrentUserProfilePercent();
        const remainingFields = getRemainingFields();
        const completedFields = getCompletedFields();

        if (storedName) {
            setName(storedName);
        }
        if (storedProfileCompletion) {
            setProfileCompletion(Number(storedProfileCompletion));
        }
        if (remainingFields) {
            setRemainingFieldsList(remainingFields);
        }
        if (completedFields) {
            setCompletedFieldsList(completedFields);
        }
    }
    useEffect(() => {
        // Load the todos on mount
        
        fetchProfileData()

        // Respond to the `storage` event
        function storageEventHandler(event) {
            if (event.key === "authUser") {
                const updatedUserData = JSON.parse(event.newValue);
                
                const storedName = updatedUserData?.first_name || "User";
                
                const completedFields = getCompletedFields();
    
                if (storedName) {
                    setName(storedName);
                }
                
                
                
            } else if(event.key === "profilePercent") {
                const updatedProfiePercent = JSON.parse(event.newValue);
                const storedProfileCompletion = updatedProfiePercent;
                if (storedProfileCompletion) {
                    setProfileCompletion(Number(storedProfileCompletion));
                }
            } else if(event.key === "remainingFields") {
                const updatedRemainingFields = JSON.parse(event.newValue);
                const remainingFields = updatedRemainingFields;
                if (remainingFields) {
                    setRemainingFieldsList(remainingFields);
                }
            } else if(event.key === "completedFields") {
                const updatedCompletedFields = JSON.parse(event.newValue);
                const completedFields = updatedCompletedFields;
                if (completedFields) {
                    setCompletedFieldsList(completedFields);
                }
            }
        }
        setLoading(false)
        // Hook up the event handler
        window.addEventListener("storage", storageEventHandler);
        return () => {
            // Remove the handler when the component unmounts
            window.removeEventListener("storage", storageEventHandler);
        };
    }, []);



  useEffect(() => {
    async function fetchData() {

        get(url.GET_USER_INFO, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {
        
            
            setCurrentUserProfilePercentWithResponse(response);

            fetchProfileData()
        }).catch((e) => {
            // window.alert("Sorry!\n"+e)
            // setLoading(false)
        }).finally(() => {
        });   
        
        // setLoading(false)
      
    
    }
    
    fetchData();

    // Perform your componentDidMount logic here
    // console.log('Component has mounted');

  }, []); 


    // useEffect(() => {
    //     async function fetchData() {

    //         const storedName = getCurrentUser()?.first_name || "User";
    //         const storedProfileCompletion = getCurrentUserProfilePercent();
    //         const remainingFields = getRemainingFields();
    //         const completedFields = getCompletedFields();

    //         if (storedName) {
    //             setName(storedName);
    //         }
    //         if (storedProfileCompletion) {
    //             setProfileCompletion(Number(storedProfileCompletion));
    //         }
    //         if (remainingFields) {
    //             setRemainingFieldsList(remainingFields);
    //         }
    //         if (completedFields) {
    //             setCompletedFieldsList(completedFields);
    //         }

    //         // get(url.GET_ALL_COURSES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

    //         //     setLoading(false)

    //         // }).catch((e) => {
    //         //     window.alert("Sorry!\n"+e)
    //         // }).finally(() => {
    //         //     setLoading(false)
    //         // });   
            
    //         setLoading(false)
        
        
    //     }
    //     fetchData();

    //     // Perform your componentDidMount logic here
    

    // }, []); 


    const findMessage = (key, sets) => {
        return sets.find(k => k.key === key);
    }

    const renderRemainingFields = (field) => {

        let fields = [
            { keys: ["first_name", "last_name", "email", "mobile_no", "birthdate", "gender", "profile_picture", "address", "interest_id", "postal_code"], redirect : '/aboutme/my-profile' },
            { keys: ["desired_job_role_id", "myanswers"], redirect : '/aboutme/my-panel' },
            { keys: ["summary", "links", "skills"], redirect : '/aboutme/experience-profile' },
            // { keys: ["myanswers"], redirect : '/aboutme/my-answer' },
            { keys: ["experiences"], redirect : '/aboutme/my-experience' },
            { keys: ["qualification"], redirect : '/aboutme/my-qualification' },
        ]

        let sets = [
			{ key: "first_name", label: "First Name is missing"},
			{ key: "last_name", label: "Last Name is missing"},
			{ key: "email", label: "Email is missing"},
			{ key: "mobile_no", label: "Mobile Number is missing"},
			{ key: "birthdate", label: "Birhdate is missing"},
			{ key: "gender", label: "Gender is missing"},
			{ key: "profile_picture", label: "Profile Picture is missing"},
			{ key: "address", label: "Address is missing"},
			{ key: "interest_id", label: "Interest is missing"},
			{ key: "postal_code", label: "Postal Code is missing"},
			{ key: "desired_job_role_id", label: "Desired Job Role is missing"},
			{ key: "summary", label: "Summary is missing in Experience Profile"},
			{ key: "links", label: "Links is missing in Experience Profile"},
			{ key: "skills", label: "Skills is missing in Experience Profile"},
			{ key: "myanswers", label: "My Answers is missing"},
			{ key: "experiences", label: "Experiences is missing"},
			{ key: "qualification", label: "Qualification is missing"},
		]

        return fields.map((f,i) => {

            if (f.keys.includes(field)) {
                let fieldInfo = findMessage(field,sets)
                // console.log("INFO ",fieldInfo)
                return(<div className="row" style={{ cursor : 'pointer', marginLeft : 0, paddingLeft: 0, paddingRight : 0 }} onClick={() => {
                    toggleRightCanvas()
                    navigate(f.redirect)
                }} >
                <dt className="col-sm-10" style={{ color : '#ec536c' }}><i style={{ cursor: 'pointer', color : '#ec536c' }} className="fa fas fa-question-circle"></i> {fieldInfo.label}</dt>
                <dd className="col-sm-2" >
                    <i style={{ color : '#ec536c', textAlign : 'right' }} className="fa fas fa-arrow-right ms-2"></i>
                    {/* <u><span>Redirect</span> <i className="fa fas fa-arrow-right ms-2"></i></u> */}
                    {/* <button className="btn btn-primary waves-effect waves-light">
                        
                    </button> */}
                </dd>
                </div>)
                // return(<h6 className="mb-3">{field}</h6>)
            }

            
        })



        
    }



    return (
        <React.Fragment>
        
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-9">
                    <div className="title">
                        <span><img src="/images/rate_ic.svg" alt="" /></span>
                        <h2 className="info">Welcome {name}</h2>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="label">Complete your Profile</div>
                    <div className="progress_info" id="popover5" onClick={toggleRightCanvas} data-container="body">
                        <div className="progress" role="progressbar" aria-label="Animated striped" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: `${profileCompletion}%`}}></div>
                        </div>
                        <div className="progress_time">
                            <small>{profileCompletion}% { profileCompletion < 100 &&  <i style={{ cursor: 'pointer' }} className="fa fa-exclamation-circle"></i>}</small>
                        </div>
                    </div>
                    {/* <Offcanvas
                        isOpen={isTop}
                        direction="top"
                        toggle={toggleTopCanvas}>
                        <OffcanvasHeader toggle={toggleTopCanvas}>
                            Below information is remaining
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            {
                                remainingFieldsList.length > 0 ?
                                remainingFieldsList.map((field) => {
                                    return(<h5 className="mb-3">{field}</h5>)
                                }) 
                                : <></>
                            }
                        </OffcanvasBody>
                    </Offcanvas> */}
                    {/* Right offcanvas */}
                    
                    {/* <button className="btn btn-primary waves-effect waves-light">
                        <span>Send</span> <i className="fab fa-telegram-plane ms-2"></i>
                    </button> */}

                    <Offcanvas
                        isOpen={isRight}
                        direction="end"
                        toggle={toggleRightCanvas}>
                        <OffcanvasHeader toggle={toggleRightCanvas}>
                            Missing information in this section:
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            {
                                remainingFieldsList.length > 0 ?
                                remainingFieldsList.map((field) => {
                                    return(
                                        <dl className="row mb-0">
                                            {renderRemainingFields(field)}
                                        </dl>
                                    )
                                } 
                                    
                                ) 
                                : <></>
                            }
                        </OffcanvasBody>
                    </Offcanvas>
                </div>
            </div>
        </div>
        
        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(Intro);