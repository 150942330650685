import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { FieldArray, useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
import { calculateDiscount, formatOption, formatDate, getCurrentUser, getCurrentDateInDefaultFormat, setCurrentUserProfilePercentWithResponse } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import Bootstrap Select CSS
import $ from 'jquery'; // Import jQuery
// import 'bootstrap-select'; // Import Bootstrap Select JS



// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import the bootstrap-select CSS
import 'bootstrap-select/dist/js/bootstrap-select.min.js'; // Import the bootstrap-select JavaScript

import Header from "./Header";
import Footer from "./Footer";
import moment from "moment";


const BuildMyCV = (props) => {
  document.title = "My Experience - Virtual Skills Platform";

  const animatedComponents = makeAnimated();

  const [LinksMaster, setLinksMaster] = useState([
    {
      label: "LinkedIn",
      value: "LinkedIn"
    },
    {
      label: "Portfolio",
      value: "Portfolio"
    },
    {
      label: "Behance",
      value: "Behance"
    },
    {
      label: "GitHub",
      value: "GitHub"
    },
    {
      label: "Instagram",
      value: "Instagram"
    },
  ])


  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [isEditMode, setEditMode] = useState(false)
  const [isSummaryLoading, setIsSummaryLoading] = useState(false)
  const [isSummaryEditMode, setIsSummaryEditMode] = useState(false)

  const [isExperienceLoading, setIsExperienceLoading] = useState(false)
  const [experiences, setExperiences] = useState([])

  const [isQualificationLoading, setIsQualificationLoading] = useState(false)
  const [qualifications, setQualifications] = useState([])

  const [skillsMaster, setSkillsMaster] = useState([])
  const [isMySkillsLoading, setIsMySkillsLoading] = useState(false)
  const [isMySkillsEditMode, setIsMySkillsEditMode] = useState(false)
  const [isNewSkillsEditMode, setIsNewSkillsEditMode] = useState(false)

  const [isLinksLoading, setIsLinksLoading] = useState(false)
  const [isLinksEditMode, setIsLinksEditMode] = useState(false)
  const [lockValues, setLockValues] = useState(null)
  // const [links, setLinks] = useState([{ title: '', link: '' }]);
  const [links, setLinks] = useState([]);


  const [textareabadge, settextareabadge] = useState(0)
  const [placementbadge, setplacementbadge] = useState(false)
  const [textcount, settextcount] = useState(0)
  
  const [userInfo, setUserInfo] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email : "",
    mobileNo : "",
    age : "",
    gender : "",
    ethnicity : "",
    address : "",
    description : "",
    interest :  "",
})

  const JOB_ROLES = [
    { label: "Software Engineer", value: "Software Engineer" },
    { label: "Character Designer", value: "Character Designer" },
  ]

  const SKILLS = [
    { label: "Javascript", value: "Javascript" },
    { label: "Python", value: "Python" },
    { label: "Java", value: "Java" },
    { label: "PHP", value: "PHP" },
    { label: "Swift", value: "Swift" },
  ]

  const [mySkillsFormValues, setMySkillsFormValues] = useState({
    skills: [],
  })
  const [newSkillsFormValues, setNewSkillsFormValues] = useState({
    skill_name: "",
  })

  const [myLinksFormValues, setMyLinksFormValues] = useState({
    links: [],
  })




  const validation_links = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: myLinksFormValues,
    validationSchema: Yup.object({
        // skills: Yup.string().required("Please enter skills"),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here
      
      setIsLinksLoading(true);
      
      

      let newBody = [];


      values.links.map((link,i) => {
        console.log("L ",link)
          newBody.push({ title : link.title.value, link : link.link })
      })

      let formData = {links: newBody};

      // console.log("FORMDATA ",formData,values)

      // setTimeout(() => {
      //   setIsLinksLoading(false)
      //   setIsLinksEditMode(false)
      // },3000)


      post(url.SAVE_USER_LINKS, formData, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

                  
          let message = response?.response || "";
          let responseData = response?.data || [];

          setCurrentUserProfilePercentWithResponse(response);

          
          window.alert("Success!\n"+message)
          // window.history.go(-1);
          setIsLinksLoading(false)
          setIsLinksEditMode(false)

      }).catch((e) => {
          window.alert("Sorry!\n"+e)
          setIsLinksLoading(false)
          setIsLinksEditMode(false)
          
      }).finally(() => {
      });  
      
     
    },
  });

  const validation_skills = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: mySkillsFormValues,
    validationSchema: Yup.object({
        // skills: Yup.string().required("Please enter skills"),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here
      
      setIsMySkillsLoading(true);
      
      // console.log("SUBMITTED",values)


      post(url.SAVE_USER_SKILLS, values, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

                
        let message = response?.response || "";
        let responseData = response?.data || [];

        setCurrentUserProfilePercentWithResponse(response);

        
        window.alert("Success!\n"+message)
        
        setSkillsMaster(formatOption(responseData,"name","skill_id") || []);
        loadUserSkillsData(formatOption(responseData,"name","skill_id") || [])

        // window.history.go(-1);

        // setIsMySkillsLoading(false)
        // setIsMySkillsEditMode(false)
        // validation_skills.resetForm()

    }).catch((e) => {
        window.alert("Sorry!\n"+e)
        setIsMySkillsLoading(false)
        setIsMySkillsEditMode(false)
        
    }).finally(() => {
    });   

      // setTimeout(() => {
      //   setIsMySkillsLoading(false)
      //   setIsMySkillsEditMode(false)
      // },3000)
      
     
    },
  });

  const validation_new_skill = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: newSkillsFormValues,
    validationSchema: Yup.object({
        skill_name: Yup.string().required("Please enter skill"),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here
      
      setIsMySkillsLoading(true);
      
      

      let newBody = Object.assign({}, values);

      newBody.skills = validation_skills.values.skills;


      console.log("SUBMITTED NEW SKILL",newBody)

      post(url.SAVE_USER_WITH_NEW_SKILLS, newBody, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

                
        let message = response?.response || "";
        let responseData = response?.data || [];

        setCurrentUserProfilePercentWithResponse(response);

        
        window.alert("Success!\n"+message)

        console.log("DATAT ",responseData)
        // window.location.reload();
        // loadSkillsMasterData()

        setSkillsMaster(formatOption(responseData,"name","skill_id") || []);
        loadUserSkillsData(formatOption(responseData,"name","skill_id") || [])

        // setIsMySkillsLoading(false)
        // setIsNewSkillsEditMode(false)
        // setIsMySkillsEditMode(false)

    }).catch((e) => {
        window.alert("Sorry!\n"+e)
        setIsMySkillsLoading(false)
        setIsNewSkillsEditMode(false)
        setIsMySkillsEditMode(false)
        
    }).finally(() => {
    });   

      
     
    },
  });

  const [summaryFormValues, setSummaryFormValues] = useState({
    summary: "",
  })

  const validation_summary = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: summaryFormValues,
    validationSchema: Yup.object({
        summary: Yup.string().required("Please enter summary"),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here
      
      setIsSummaryLoading(true);
      
      // console.log("SUBMITTED")
      // setTimeout(() => {
      //   setIsSummaryLoading(false)
      //   setIsSummaryEditMode(false)
      // },3000)
      post(url.SAVE_USER_SUMMARY, values, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

                
        let message = response?.response || "";
        let responseData = response?.data || [];

        setCurrentUserProfilePercentWithResponse(response);


        

        window.alert("Success!\n"+message)
        // window.history.go(-1);
        setIsSummaryLoading(false)
        setIsSummaryEditMode(false)

    }).catch((e) => {
        window.alert("Sorry!\n"+e)
        setIsSummaryLoading(false)
        setIsSummaryEditMode(false)
        
    }).finally(() => {
    });    
      
     
    },
  });

  const loadUserSkillsData = (skills) => {
    // setIsMySkillsLoading(true)
    get(url.GET_USER_CV_SKILLS, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

      // console.log("RESPONSE SKILLS",response)
    
      let responseData = response?.data || [];

      setCurrentUserProfilePercentWithResponse(response);

      // console.log("D ",setMultiFieldValues(skills,responseData,"value","skill_id") || [])

      validation_skills.setFieldValue('skills', setMultiFieldValues(skills,responseData,"value","skill_id") || []);
      

      // setListData(existingData);
      setIsMySkillsLoading(false)
      setIsNewSkillsEditMode(false)
      setIsMySkillsEditMode(false)

    }).catch((e) => {
      window.alert("Sorry!\n"+e)
      setIsMySkillsLoading(false)
      setIsNewSkillsEditMode(false)
      setIsMySkillsEditMode(false)
    }).finally(() => {
    });  
  }

  const loadUserLinksData = () => {
    // setIsMySkillsLoading(true)
    get(url.GET_USER_CV_LINKS, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

      // console.log("RESPONSE LINKS ",response)
    
      let responseData = response?.data || [];

      let preData = []
      
      responseData.map((l,i) => {
        preData.push({ title: { label : l.title, value: l.title }, link : l.link })
      })

      // console.log("PRE DATA ",preData)
      validation_links.setFieldValue('links', preData || []);
      

      setLinks(responseData);
      setIsLinksLoading(false)

    }).catch((e) => {
      window.alert("Sorry!\n"+e)
      setIsLinksLoading(false)
    }).finally(() => {
    });  
  }



const setMultiFieldValues = (masterArr,arr,masterKey,sourceFieldKey) => { 
  if (!masterArr || masterArr.length === 0) {
    return []; // Return an empty array or handle the case as needed
  }

  if (!arr || arr.length === 0) {
    return []; // Return an empty array or handle the case as needed
  }
  
  let finalArr = [];

  masterArr.forEach((skillsMaster) => {
    
    arr.forEach((userSkills) => {

      // console.log(skillsMaster[masterKey],userSkills[sourceFieldKey])
      if(skillsMaster[masterKey] == userSkills[sourceFieldKey]) {

        finalArr.push({ label : skillsMaster.label, value: skillsMaster.value })
      }

    });

  });

  return finalArr;
}

  const loadSkillsMasterData = () => {
    setIsMySkillsLoading(true)
    get(url.GET_ALL_SKILLS, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

      // console.log("RESPONSE  SKILLS",response)
    
      let responseData = response?.data || [];

      setSkillsMaster(formatOption(responseData,"name","skill_id") || []);
      loadUserSkillsData(formatOption(responseData,"name","skill_id") || [])

      // setListData(existingData);
      // setIsMySkillsLoading(false)

    }).catch((e) => {
      window.alert("Sorry!\n"+e)
      // setIsMySkillsLoading(false)
    }).finally(() => {
    });  
  }

  const loadSummaryData = () => {
    setIsSummaryLoading(true)
    get(url.GET_USER_CV_SUMMARY, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

      // console.log("RESPONSE SUMMARY ",response)
    
      let responseData = response?.data || [];

      validation_summary.setFieldValue('summary', responseData?.summary || "");
      

      // setListData(existingData);
      setIsSummaryLoading(false)

    }).catch((e) => {
      window.alert("Sorry!\n"+e)
      setIsSummaryLoading(false)
    }).finally(() => {
    });  
  }

  const loadQualificationData = () => {
    setIsQualificationLoading(true)
      get(url.GET_USER_QUALIFICATION, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {
          let responseData = response.data
          
          let qualifications = responseData.qualifications
          
          // console.log("RES ",response,qualifications)

          setQualifications(qualifications);

          setLoading(false)

      }).catch((e) => {
          window.alert("Sorry!\n"+e)
          setLoading(false)
      }).finally(() => {
      });    
  }

  const loadExperienceData = () => {
    setIsExperienceLoading(true)
    get(url.GET_EXPERIENCES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {
      let responseData = response.data
      
      let experiences = responseData.experiences
      
      setExperiences(experiences);

      setIsExperienceLoading(false)

    }).catch((e) => {
        window.alert("Sorry!\n"+e)
        setIsExperienceLoading(false)
    }).finally(() => {
    });     
  }

  useEffect(() => {
    async function fetchData() {


        loadSkillsMasterData()
        loadSummaryData()
        loadQualificationData()
        loadExperienceData()
        loadUserLinksData()

        let user = getCurrentUser();
        // console.log("USER ",user)

        let userObj = {
            first_name: user.first_name,
            middle_name: user.middle_name,
            last_name: user.last_name,
            email : user.email,
            mobileNo : `(+44)-${user?.mobile_no || "-"}`,
            birthDate: user?.birthdate || "",
            age : calculateAge(user?.birthdate || new Date()).toFixed(),
            gender : user.gender ? { label: user.gender.toUpperCase(), value: user.gender } : {},
            ethnicity : { label: user?.ethnicity || "Prefer not to say", value: user?.ethnicity || "Prefer not to say" },
            address : user?.address || "-",
            description : user?.aboutme || "-",
            interest : { label: user?.interest?.name || "Learn a new skill", value: user?.interest?.id || "Learn a new skill" },
        }

        // console.log("USER ",userObj)

        setUserInfo(userObj)

        // get(url.GET_ALL_COURSES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

        //     setLoading(false)

        // }).catch((e) => {
        //     window.alert("Sorry!\n"+e)
        // }).finally(() => {
        //     setLoading(false)
        // });   
        
        setLoading(false)
      
    
    }
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 



  const handleSelect = (selectedOptions,key) => {
    validation_skills.setFieldValue(key, selectedOptions);
  };

  const calculateAge = (date) => {
    const birthDate = moment(date); // Parse the given date
    const currentDate = moment(); // Get the current date

    // Calculate the difference in years
    const age = currentDate.diff(birthDate, 'years');

    // console.log("AGE ",age,date)
    return age;
  };

  const handleAddLink = () => {
    // const updatedLinks = [...validation_links.values.links, { title: '', link: '' }]
    
    const updatedLinks = [...validation_links.values.links, { title: '', link: '' }]
    validation_links.setFieldValue('links',updatedLinks)
    setLinks(updatedLinks);
  };

  const handleRemoveLink = (index) => {
    // const updatedLinks = [...links];
    const updatedLinks = validation_links.values.links;
    updatedLinks.splice(index, 1);
    // console.log(" S ",links,validation_links.values.links,updatedLinks)
    validation_links.setFieldValue('links',updatedLinks)
    setLinks(updatedLinks);

    if(validation_links.values.links.length == 0) {
      setIsLinksEditMode(false)
    }
    
  };

  const handleDropdownSelect = (selectedOptions,key) => {
    // console.log("KEY ",key)
      validation_links.setFieldValue(key, selectedOptions);
  };

  const handleEditOn = (name,functions,mode,context) => {
    setLockValues({ ...lockValues, [name]: context.values})
    // setEditMode(mode)
    functions.map((state,i) => {
      state(mode)
    })
  }
  
  const handleEditCancel = (name,functions,mode,context) => {
    let vals = lockValues
    context.setValues(vals[name])
    delete vals[name];
    setLockValues(vals)
    functions.map((state,i) => {
      state(mode)
    })
    settextareabadge(false)
    // setEditMode(mode)
  }



const textareachange = (event) => {
  const count = event.target.value.length
  
  if (count > 0) {
    settextareabadge(true)
  } else {
    settextareabadge(false)
  }
  settextcount(event.target.value.length)
}

  return (
    <React.Fragment>
    {/* isLoading ? <><Header /><section className="about_sec"><div className="container"><Spinners  /></div></section></> : */}
    { 
      <>
                    
                
                <Header />


            <section className="about_sec">
                {/* <Form className="needs-validation"  onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}>  */}
                    <div className="container">
                      
                        
                        {/* <!-- About --> */}
                        <div className="about_main">
                            <ul className="breadcrumb">
                                <li><Link to="/dashboard" href="#" title="Dashboard">Dashboard</Link></li>
                                <li><a href="#" title="About Me">About Me</a></li>
                                <li>Experience Profile</li>
                            </ul>
                            {/* <div className="row align-items-end justify-content-between mb-4 mb-xl-5 g-0">
                                <div className="col-md-auto col-sm-12">
                                    <h3>Experience Profile</h3>
                                </div>
                                <div className="col-md-auto col-sm-12 text-end">
                                   
                                    { isEditMode && <button type="submit" className="btns green mr-5">{isLoading ? <Spinners  /> : <><img src="/images/check_ic.svg" alt="" />Save</>}</button>}
                                   
					                        { !isEditMode && <button type="button" onClick={() => setEditMode(true) } className="btns gray"><img src="/images/edit_ic.svg" alt="" />Edit My CV</button>}
                                </div>
                                
                            </div> */}
                            <div class="row align-items-end justify-content-between mb-5 g-0">
                              <div class="col-md-auto col-sm-12">
                                <h3>Experience Profile </h3>
                              </div>
                              {/* <div class="col-md-auto col-sm-12 text-end">
                                <a href="#" class="download_btn"><img src="/images/download_ic.svg" alt="" />Download CV</a>
                              </div> */}
                            </div>
                           
                           {/* QUALIFICATION FORM */}

                           {/* <MyQualificationForm isEditMode={isEditMode} job_roles={JOB_ROLES} validation={validation} /> */}

                           {/* <div class="build_info">
                              <h5>Select your favorite template design of CV</h5>
                              <div class="build_user">
                                <figure><img src="/images/build_user.jpg" alt="" /></figure>
                                <div class="upload_btn">
                                              <div class="fileUpload btn btn-primary">
                                                  <span>Edit Your Photo</span>
                                                  <input type="file" class="upload" />
                                              </div>
                                          </div>
                              </div>
                            </div> */}

                            <div class="row align-items-center justify-content-between mb-4 g-0">
                              <div class="col-md-auto col-sm-12">
                                <h3>Personal Details</h3>
                              </div>
                              <div class="col-md-auto col-sm-12 text-end">
                                <Link to="/aboutme/my-profile" class="btns gray">Edit Personal Details</Link>
                              </div>
                            </div>

                            <form class="degree_box">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="input_group">
                                    <label for="text" class="form-label">First Name</label>
                                    <h5>{userInfo.first_name}</h5>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="input_group">
                                    <label for="text" class="form-label">Middle Name</label>
                                    <h5>{userInfo.middle_name}</h5>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="input_group">
                                    <label for="text" class="form-label">Last Name</label>
                                    <h5>{userInfo.last_name}</h5>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="input_group">
                                    <label for="text" class="form-label">Mobile</label>
                                    <h5>{userInfo.mobileNo}</h5>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="input_group">
                                    <label for="text" class="form-label">Email</label>
                                    <h5>{userInfo.email}</h5>
                                  </div>
                                </div>
                              </div>
                              
                              <div class="row">
                                
                                <div class="col-md-3">
                                  <div class="input_group">
                                    <label for="text" class="form-label">Birth Date</label>
                                    {/* <h5>{userInfo.age}</h5> */}
                                    <h5>{userInfo.birthDate == "" ? "" : formatDate(userInfo?.birthDate || "")}</h5>
                                  </div>
                                </div>
                                
                                <div class="col-md-3">
                                  <div class="input_group">
                                    <label for="text" class="form-label">Gender</label>
                                    <h5>{userInfo.gender.label}</h5>
                                  </div>
                                </div>

                                {/* <div class="col-md-3">
                                  <div class="input_group">
                                    <label for="text" class="form-label">Ethnicity</label>
                                    <h5>{userInfo.ethnicity.label}</h5>
                                  </div>
                                </div> */}
                               

                              </div>

                              <div class="row">
                                <div class="col-md-3">
                                  <div class="input_group">
                                    <label for="text" class="form-label">Address</label>
                                    <h5>{userInfo.address}</h5>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-12">
                                  <div class="input_group">
                                    <label for="text" class="form-label">About Me</label>
                                    <h5>{userInfo.description}</h5>
                                  </div>
                                </div>
                              </div>

                            </form>

                            <Form className="needs-validation"  onSubmit={(e) => {
                              e.preventDefault();
                              validation_summary.handleSubmit();
                              return false;
                            }}> 
                            {/* <h3 class="mb_4">Professional Summary</h3> */}
                            <div className="row align-items-center justify-content-between mb-4 mb-xl-5 g-0">
                                <div className="col-md-auto col-sm-12">
                                    <h3>Professional Summary</h3>
                                </div>
                                <div className="col-md-auto col-sm-12 text-end">
                                   
                                  { isSummaryEditMode && <button type="submit" className="btns green me-2">{isSummaryLoading ? <Spinners  /> : <><img src="/images/check_ic.svg" alt="" />Save</>}</button>}
                                   
					                        { isSummaryEditMode && <button type="button" onClick={() => handleEditCancel('summary',[setIsSummaryEditMode],false,validation_summary) } className="btns gray"><img src="/images/close_sm_ic.svg" alt="" />Cancel</button>}
					                        { !isSummaryEditMode && <button type="button" onClick={() => handleEditOn('summary',[setIsSummaryEditMode],true,validation_summary) } className="btns gray"><img src="/images/edit_ic.svg" alt="" />Edit Summary</button>}
                                </div>
                                
                            </div>
                              <div class="degree_box">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="input_group">
                                      {/* <label for="text" class="form-label">Summary</label>
                                      <h5>Lorem ipsum dolor sit amet, ligula eget dolor. cnsectetuer adipiscing elit. Donec quam felis, ultricies nec, pellentesque eu, pretium quis sem.</h5> */}

                                        <Label htmlFor="validationCustom01">Summary</Label>
                                        {
                                            isSummaryEditMode ? 
                                            <>
                                                <Input
                                                  name="summary"
                                                  placeholder="Enter summary"
                                                  type="text"
                                                  maxLength="225"
                                                  onChange={(e) => {
                                                    validation_summary.setFieldValue('summary',e.target.value);
                                                      textareachange(e)
                                                    }
                                                  }
                                                  // onChange={validation_summary.handleChange}
                                                  onBlur={validation_summary.handleBlur}
                                                  value={validation_summary.values.summary || ''}
                                                  invalid={validation_summary.touched.summary && validation_summary.errors.summary ? true : false}
                                                />
                                            {validation_summary.touched.summary && validation_summary.errors.summary ? (
                                                <div className="invalid-feedback">{validation_summary.errors.summary}</div>
                                            ) : null}
                                            </>
                                            :
                                            <h5>{validation_summary.values.summary || '-'}</h5>
                                        }
                                         {textareabadge ? (
                                          <span className="badgecount badge badge-success">
                                            {" "}
                                            {textcount} / 225{" "}
                                          </span>
                                        ) : null}

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form>

                            <div class="row align-items-center justify-content-between mb-4 g-0">
                              <div class="col-md-auto col-sm-12">
                                <h3>My Experience</h3>
                              </div>
                              <div class="col-md-auto col-sm-12 text-end">
                                <Link to="/aboutme/my-experience" class="btns gray">Manage Experience</Link>
                              </div>
                            </div>

                            {
                                experiences.map((exp,i) => {

                                    return(
                                        <div className="degree_box" style={{ paddingTop :  i == 0  ? 35 : 35, marginBottom : experiences.length - 1 == i ? 50 : 20 }}>
                                              <div className="row">
                                              <div className="col-md-6 col-sm-4">
                                                      <div className="input_group">

                                                          <Label htmlFor="validationCustom01">Company Name</Label>
                                                              <h5>{exp?.company_name || ''}</h5>
                                                      </div>
                                                  </div>
                                                  
                                              <div className="col-md-6 col-sm-4">
                                                      <div className="input_group">
                                                          <Label>Job Title</Label>
                                                          

                                                          
                                                          {/* <h5>{exp?.jobRole?.name || ""}</h5> */}
                                                          <h5>{exp?.job_role || ""}</h5>
                                                          
                                                        
                                                          
                                                        
                                                      </div>
                                                  </div>
                                                  </div>
                                                <div className="row">
                                                <div className="col-md-6 col-sm-4">
                                                      <div className="input_group">

                                                          <Label htmlFor="validationCustom01">Location</Label>
                                                          
                                                          <h5>{exp?.location || ''}</h5>
                                                          
                                                          
                                                      </div>
                                                  </div>
                                              <div className="col-md-3 col-sm-4">
                                                      <div className="input_group">
                                                          <Label  htmlFor="validationCustom01">Start Date</Label>

                                                          
                                                          <h5>{formatDate(exp?.start_date || new Date()) || ''}</h5>
                                                          
                                                      </div>
                                                  </div>

                                                  {  <div className="col-md-3 col-sm-4">
                                                      <div className="input_group">
                                                      <Label  htmlFor="validationCustom01">End Date</Label>
                                                     
                                                        <h5>{ exp.is_current ? "Currently Working" : formatDate(exp?.end_date || getCurrentDateInDefaultFormat() ) || ''}</h5>
                                                      
                                                      </div>
                                                  </div>}
                                                
                                              </div>
                                              <div className="row">
                                            <div className="col-md-12 col-sm-12">
                                                      <div className="input_group">
                                                          
                                                          <Label>Responsibilities</Label>
                                                          
                                                          <h5>{exp?.job_description || ''}</h5>
                                                          
                                                      </div>
                                                  </div>
                                              </div>
                                        </div>)
                                      })
                                  }

                            

                            <div class="row align-items-start justify-content-between mb-4 g-0">
                              <div class="col-md-auto col-sm-12">
                                <h3>My Qualifications</h3>
                              </div>
                              <div class="col-md-auto col-sm-12 text-end">
                                  <Link to="/aboutme/my-qualification" class="btns gray">Manage Qualifications</Link>
                              </div>
                            </div>

                            {
                                qualifications.map((q,i) => {
                                  

                                    return(
                                      <div class="degree_box" style={{ paddingTop :  i == 0  ? 35 : 35, marginBottom : qualifications.length - 1 == i ? 50 : 20 }}>
                                        <div class="row">
                                          <div class="col-md-6">
                                            <div class="input_group">
                                              <Label htmlFor="validationCustom01">Institute</Label>
                                              <h5>{q?.institute_name}</h5>
                                            </div>
                                          </div>

                                          <div class="col-md-6">
                                            <div class="input_group">
                                            <Label htmlFor="validationCustom01">Course</Label>
                                              <h5>{q?.course_name}</h5>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row">
                                        <div className="col-md-6 col-sm-4">
                                              <div className="input_group">

                                                  <Label htmlFor="validationCustom01">Location</Label>
                                                  
                                                  <h5>{q.location || ''}</h5>
                                                  
                                              </div>
                                          </div>
                                          <div className="col-md-3 col-sm-4">
                                              <div className="input_group">
                                                  <Label  htmlFor="validationCustom01">Start Date</Label>

                                                 
                                                  <h5>{formatDate(q?.start_date || new Date()) || ''}</h5>
                                                  
                                              </div>
                                          </div>

                                          <div className="col-md-3 col-sm-4">
                                              <div className="input_group">
                                              <Label  htmlFor="validationCustom01">End Date</Label>
                                              
                                              <h5>{formatDate(q.end_date || getCurrentDateInDefaultFormat() ) || ''}</h5>
                                              </div>
                                          </div>
                                        
                                      </div>
                                      <div className="row">
                                          <div className="col-md-12 col-sm-12">
                                                    <div className="input_group">
                                                        
                                                        <Label>Skills</Label>
                                                        
                                                        <h5>{q.description || ''}</h5>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                      </div>)
                                })
                            }

                            {/* <div class="row align-items-start justify-content-between mb-4 g-0">
                              <div class="col-md-auto col-sm-12">
                                <h3>My Skills</h3>
                              </div>
                              <div class="col-md-auto col-sm-12 text-end">
                                <a href="#" class="btns gray">Add Skills</a>
                              </div>
                            </div> */}

                          
                              {/* <h3 class="mb_4">Professional Summary</h3> */}
                              <div className="row align-items-center justify-content-between mb-4 mb-xl-5 g-0">
                                <div className="col-md-auto col-sm-12">
                                    <h3>My Skills</h3>
                                </div>
                                <div className="col-md-auto col-sm-12 text-end">
                                   
                                    { isMySkillsEditMode && !isNewSkillsEditMode && <button type="submit" onClick={(e) => {
                                      e.preventDefault();
                                      validation_skills.handleSubmit();
                                      return false;
                                    }} className="btns green mr-5">{isMySkillsLoading ? <Spinners  /> : <><img src="/images/check_ic.svg" alt="" />Save</>}</button>}

                                    { isNewSkillsEditMode && <button type="submit" onClick={(e) => {
                                      e.preventDefault();
                                      validation_new_skill.handleSubmit();
                                      return false;
                                    }} className="btns green mr-5">{isMySkillsLoading ? <Spinners  /> : <><img src="/images/check_ic.svg" alt="" />Save New</>}</button>}
                                   
                                   { isMySkillsEditMode && !isNewSkillsEditMode && <button type="button" onClick={() => { setIsNewSkillsEditMode(true); } } className="btns blue ms-2"><img src="/images/plus.svg" alt="" style={{ height : 13 }} />Add New Skill</button>}
                                   { isMySkillsEditMode && <button type="button" onClick={() => {
                                    handleEditCancel('skills',[setIsNewSkillsEditMode,setIsMySkillsEditMode],false,validation_skills)
                                    // setIsNewSkillsEditMode(false); setIsMySkillsEditMode(false)
                                    
                                    } } className="btns gray ms-2"><img src="/images/close_sm_ic.svg" alt="" style={{ height : 13 }} />Cancel</button>}
					                        { !isMySkillsEditMode && <button type="button" onClick={() => handleEditOn('skills',[setIsMySkillsEditMode],true,validation_skills) } className="btns gray"><img src="/images/edit_ic.svg" alt="" />Edit Skills</button>}
                                </div>
                                
                            </div>
                              <div class="degree_box">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="input_group">
                                        {!isNewSkillsEditMode && <Label htmlFor="validationCustom01">Skills</Label>}
                                        {
                                          
                                          isMySkillsEditMode ?
                                          <>
                                                  
                                                  {!isNewSkillsEditMode && <>
                                                 
                                                  <Form className="needs-validation"  onSubmit={(e) => {
                                                      e.preventDefault();
                                                      validation_skills.handleSubmit();
                                                      return false;
                                                    }}> 
                                                    <Select
                                                        value={validation_skills.values.skills}
                                                        isMulti={true}
                                                        placeholder={'Search'}
                                                        onChange={(selectedOptions) => handleSelect(selectedOptions,"skills") }
                                                        options={skillsMaster}
                                                        id="skills"
                                                        classNamePrefix="selectpicker"
                                                        isValid={!validation_skills.errors.skills && validation_skills.touched.skills}
                                                    />
                                                    {validation_skills.touched.skills && validation_skills.errors.skills ? (
                                                        <div className="invalid-feedback">{validation_skills.errors.skills}</div>
                                                    ) : null}

                                                  </Form></>}

                                                  { isNewSkillsEditMode && <Form className="needs-validation"  onSubmit={(e) => {
                                                      e.preventDefault();
                                                      validation_new_skill.handleSubmit();
                                                      return false;
                                                    }}> 
                                                  <Label>Add new skill to list</Label>

                                                  <Input
                                                        name="skill_name"
                                                        placeholder="Enter skill name"
                                                        type="text"
                                                        onChange={validation_new_skill.handleChange}
                                                        onBlur={validation_new_skill.handleBlur}
                                                        value={validation_new_skill.values.skill_name || ''}
                                                        invalid={validation_new_skill.touched.skill_name && validation_new_skill.errors.skill_name ? true : false}
                                                    />
                                                  {validation_new_skill.touched.skill_name && validation_new_skill.errors.skill_name ? (
                                                      <div className="invalid-feedback">{validation_new_skill.errors.skill_name}</div>
                                                  ) : null}
                                                  </Form>}
                                                </>
                                                :
                                                validation_skills.values.skills.length > 0 ? 
                                                  <h5>{
                                                    validation_skills.values?.skills.map((skill,i) => {

                                                      if(skill?.value)
                                                      return(<span className="skill-tag">{skill.label || ""}</span>)

                                                    })
                                                  }</h5>
                                                  :
                                                  <h5 className="mt-2" style={{ color: '#dbdbdb', paddingBottom : 0 }}>No Skills Added</h5>
                                          }

                                    </div>
                                  </div>
                                </div>
                              </div>

                            {/* <form class="degree_box">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="input_group">
                                    <label for="text" class="form-label">Skills</label>
                                    <h5 class="brd">Lorem ipsum dolor sit amet, ligula eget dolor. cnsectetuer adipiscing elit. Donec quam felis, ultricies nec, pellentesque eu, pretium quis sem.</h5>
                                    <p>Add your skills followed by comma (,) Lorem ipsum dolor sit amet adipiscing quam.</p>
                                  </div>
                                </div>
                              </div>
                            </form>

                            <form class="degree_box">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="input_group">
                                    <label for="text" class="form-label">Skills</label>
                                    <input type="text" class="form-control" id="tagInput" value="Html" data-role="tagsinput" />
                                  </div>
                                </div>
                              </div>
                            </form> */}

                            <Form className="needs-validation"  onSubmit={(e) => {
                              e.preventDefault();
                              validation_links.handleSubmit();
                              return false;
                            }}> 
                              <div className="row align-items-center justify-content-between mb-4 mb-xl-5 g-0">
                                <div className="col-md-auto col-sm-12">
                                    <h3>Websites & Social Links</h3>
                                </div>
                                <div className="col-md-auto col-sm-12 text-end" style={{ display: 'flex' }}>
                                    {isLinksEditMode && (
                                        <button type="button" className="btns gray me-2" onClick={handleAddLink}>
                                          <><img src="/images/plus_ic.svg" alt="" />Add Link</>
                                        </button>
                                    )}
                                    { isLinksEditMode && <button type="submit" className="btns green me-0">{isLinksLoading ? <Spinners  /> : <><img src="/images/check_ic.svg" alt="" />Save</>}</button>}
                                    { isLinksEditMode && <button type="button" onClick={() => {
                                    handleEditCancel('links',[setIsLinksEditMode],false,validation_links)
                                    // setIsNewSkillsEditMode(false); setIsMySkillsEditMode(false)
                                    
                                    } } className="btns gray ms-2"><img src="/images/close_sm_ic.svg" alt="" style={{ height : 13 }} />Cancel</button>}
					                        { !isLinksEditMode && <button type="button" onClick={() => handleEditOn('links',[setIsLinksEditMode],true,validation_links) } className="btns gray"><img src="/images/edit_ic.svg" alt="" />Edit Links</button>}
                                </div>
                                
                            </div>
                              <div class="degree_box">
                                {/* <div class="row">
                                  <div class="col-md-12">
                                    <div class="input_group">
                                 
                                        <Label htmlFor="validationCustom01">LinkedIn</Label>
                                        {
                                            isLinksEditMode ? 
                                            <>
                                                <Input
                                                  name="linkedIn"
                                                  placeholder="LinkedIn URL"
                                                  type="text"
                                                  onChange={validation_links.handleChange}
                                                  onBlur={validation_links.handleBlur}
                                                  value={validation_links.values.linkedIn || ''}
                                                  invalid={validation_links.touched.linkedIn && validation_links.errors.linkedIn ? true : false}
                                              />
                                            {validation_links.touched.linkedIn && validation_links.errors.linkedIn ? (
                                                <div className="invalid-feedback">{validation_links.errors.linkedIn}</div>
                                            ) : null}
                                            </>
                                            :
                                            <h5>{validation_links.values.linkedIn || '-'}</h5>
                                        }

                                    </div>
                                  </div>
                                </div> */}

                                {/* <div class="row">
                                  <div class="col-md-12">
                                    <div class="input_group">
                                  
                                        <Label htmlFor="validationCustom01">Website</Label>
                                        {
                                            isLinksEditMode ? 
                                            <>
                                                <Input
                                                  name="website"
                                                  placeholder="Website URL"
                                                  type="text"
                                                  onChange={validation_links.handleChange}
                                                  onBlur={validation_links.handleBlur}
                                                  value={validation_links.values.website || ''}
                                                  invalid={validation_links.touched.website && validation_links.errors.website ? true : false}
                                              />
                                            {validation_links.touched.website && validation_links.errors.website ? (
                                                <div className="invalid-feedback">{validation_links.errors.website}</div>
                                            ) : null}
                                            </>
                                            :
                                            <h5>{validation_links.values.website || '-'}</h5>
                                        }

                                    </div>
                                  </div>
                                </div> */}
                                
                                {/* <div class="row">
                                  <div class="col-md-12 mb-4">
                                    <div class="col-md-auto col-sm-12">
                                      <h3>Other Links</h3>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="input_group">
                                 
                                        <Label htmlFor="validationCustom01">Title</Label>
                                        {
                                            isLinksEditMode ? 
                                            <>
                                                <Input
                                                  name="links_other_title"
                                                  placeholder="Enter title"
                                                  type="text"
                                                  onChange={validation_links.handleChange}
                                                  onBlur={validation_links.handleBlur}
                                                  value={validation_links.values.links_other_title || ''}
                                                  invalid={validation_links.touched.links_other_title && validation_links.errors.links_other_title ? true : false}
                                              />
                                            {validation_links.touched.links_other_title && validation_links.errors.links_other_title ? (
                                                <div className="invalid-feedback">{validation_links.errors.links_other_title}</div>
                                            ) : null}
                                            </>
                                            :
                                            <h5>{validation_links.values.links_other_title || '-'}</h5>
                                        }

                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="input_group">
                                 
                                        <Label htmlFor="validationCustom01">Link</Label>
                                        {
                                            isLinksEditMode ? 
                                            <>
                                                <Input
                                                  name="other_link"
                                                  placeholder="Enter link"
                                                  type="text"
                                                  onChange={validation_links.handleChange}
                                                  onBlur={validation_links.handleBlur}
                                                  value={validation_links.values.other_link || ''}
                                                  invalid={validation_links.touched.other_link && validation_links.errors.other_link ? true : false}
                                              />
                                            {validation_links.touched.other_link && validation_links.errors.other_link ? (
                                                <div className="invalid-feedback">{validation_links.errors.other_link}</div>
                                            ) : null}
                                            </>
                                            :
                                            <h5>{validation_links.values.other_link || '-'}</h5>
                                        }

                                    </div>
                                  </div>
                                </div> */}

                                {/* CODE START HERE */}

                                {
                                  validation_links.values.links.length == 0 ?
                                    <h5 className="mt-2 mb-5" style={{ color: '#dbdbdb', paddingBottom : 0 }}>No Links Added</h5>
                                  :
                                  validation_links.values.links.map((link, index) => {
                                    // console.log("VAL ",validation_links.values.links[index])
                                    // return <></>
                                    return(
                                      <>
                                        <div className="row" key={index}>
                                        <div className="col-md-5" key={index}>
                                          <div className="input_group">
                                            <Label htmlFor={`links[${index}].title`}>Title</Label>
                                            {isLinksEditMode ? (
                                              <>
                                                {/* <Input
                                                  name={`links[${index}].title`}
                                                  placeholder="Enter title"
                                                  type="text"
                                                  onChange={validation_links.handleChange}
                                                  onBlur={validation_links.handleBlur}
                                                  value={validation_links.values.links[index]?.title}
                                                /> */}
                                                
                                                <Select
                                                    name={`links[${index}].title`}
                                                    isMulti={false}
                                                    onBlur={validation_links.handleBlur}
                                                    value={validation_links.values.links[index]?.title}
                                                    onChange={(selectedOptions) => handleDropdownSelect(selectedOptions,`links[${index}].title`)}
                                                    // onChange={validation_links.handleChange}
                                                    options={LinksMaster}
                                                    id={`links[${index}].title`}
                                                    classNamePrefix="selectpicker"
                                                    isValid={!validation_links.errors.links && validation_links.touched.links}
                                                />
                                              </>
                                            ) : (
                                              <h5>{validation_links.values.links[index]?.title.label || ''}</h5>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-5">
                                          <div className="input_group">
                                            <Label htmlFor={`links[${index}].link`}>Link</Label>
                                            {isLinksEditMode ? (
                                              <Input
                                                name={`links[${index}].link`}
                                                placeholder="Enter link"
                                                type="text"
                                                onChange={validation_links.handleChange}
                                                onBlur={validation_links.handleBlur}
                                                value={validation_links.values.links[index]?.link}
                                              />
                                            ) : (
                                              <h5>{validation_links.values.links[index]?.link || '-'}</h5>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-2">
                                          {isLinksEditMode && (
                                              <button
                                                type="button"
                                                className="btns red mt-2"
                                                onClick={() => handleRemoveLink(index)}
                                              >
                                                Remove
                                              </button>
                                            )}
                                        </div>
                                        </div>
                                        </>
                                      )
                                    })
                                }
                                 

                                {/* CODE END HERE */}
                                
                              </div>
                            </Form>

                            {/* <div class="row align-items-start justify-content-between mb-4 g-0">
                              <div class="col-md-auto col-sm-12">
                                <h3>Websites & Social Links</h3>
                              </div>
                              <div class="col-md-auto col-sm-12 text-end">
                                <a href="#" class="btns gray">Add Links</a>
                              </div>
                            </div>

                            <form class="degree_box">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="input_group">
                                    <label for="text" class="form-label">Title</label>
                                    <h5>Lorem ipsum dolor sit amet, ligula eget dolor.</h5>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="input_group">
                                    <label for="text" class="form-label">Links</label>
                                    <h5>Lorem ipsum dolor sit amet, ligula eget dolor.</h5>
                                  </div>
                                </div>
                              </div>
                            </form> */}

                        </div>

                    </div>
                {/* </Form> */}
            </section>

            
                <Footer />
      </>
    }
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(BuildMyCV);